export const medflow = [
  {
    title: "Cardiac Physcial Assessment",
    subtext: "Add Cardiac Physcial Assessment Description",
    points: [
      "Add benefits",
      "Same-day assessment",
    ],
  },
  {
    title: "Preventative Cardiac Evaluation",
    subtext: "Same-day cardiac evaluation",
    points: [     
      "Same-day evaluation",
      "Add Preventive cardiac evaluation benefits",
    ],
  },
];
