export const heartflowone = [
  {
    title: "Diagnostics & Procedures",
    points: [
      "Treadmill & nuclear stress tests",
      "Echocardiogram",
      "Holter & event monitoring",
      "Pacemaker/defibrillator checks",
      "Cardiac catheterization",
      "Coronary angioplasties",
      "Cardioversion",
    ],
  },
];
