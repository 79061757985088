import React from "react";
import Image from "next/image";

export const CTAVeinTwo = ({ headingText, painText, solutionText, ctaText }: any) => {

  return (
  <section className="py-12 md:py-20">
  <div className="container mx-auto ">
    <h2 className="font-heading tracking-tighter text-[2.5rem] sm:text-[2.81rem] md:text-5xl lg:text-[3.5rem] xl:text-6xl  mb-8">{headingText || ``}</h2>
    <div className="pb-10 pt-20 lg:pt-10 px-8 sm:px-8 xl:px-32 bg-blue-800 rounded-3xl">
      <div className="flex flex-wrap -mx-4 items-center">
        <div className="w-full lg:w-1/2 px-4 mb-12 lg:mb-0">
          <div className="max-w-full mx-auto">
            <h3 className="tracking-tighter text-white text-[2.1rem] sm:text-[2.3rem] md:text-[2.45rem] lg:text-[2.75rem] xl:text-5xl leading-tight mb-6">{painText || `Are you suffering from vein conditions like varicose veins or spider veins?`}</h3>
            {/* <h3 className="text-white text-4xl lg:text-5xl tracking-tight mb-6">{painText || `Are you suffering from vein conditions like varicose veins or spider veins?`}</h3> */}
            <p className="max-w-full text-lg text-slate-400 mb-6">{solutionText || `Look no further for state-of-the-art treatments using advanced techniques like ClosureFast™ and VenaSeal™. Take action today and schedule an appointment with our skilled physicians for a comprehensive evaluation.`}</p>
            <a className="group inline-flex py-5 px-8 items-center justify-center leading-none font-medium text-white hover:text-black border border-white rounded-full hover:bg-white transition duration-200" href="tel:281-955-9158">
              <span className="mr-2">{ctaText || `Schedule an Appointment`}</span>
              <span className="group-hover:rotate-45 transform transition duration-100">
                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9 1L1 9" stroke="currentColor" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
                  <path d="M9 8.33571V1H1.66429" stroke="currentColor" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
                </svg>
              </span>
            </a>
          </div>
        </div>
        <div className="w-full lg:w-1/2 px-4">
          <Image 
            alt="Varicose Treatment"
            src="/images/clinic/Varicose-Veins-Treatment.webp"
            style={{
              aspectRatio: "888/1332"
            }}
            height={888}
            className="block rounded-3xl" 
            width={1332}
            //2664 × 888 1332
          />
        </div>
      </div>
    </div>
  </div>
</section>
  )
}