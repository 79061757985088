export const heartflowtwo = [
  {
    title: "Know the Warning Signs",
    subtext: "Don't ignore symptoms that could signal heart disease! Contact us right away if you experience:",
    points: [
      "Chest, jaw, or mid-scapular pain",
      "Shortness of breath",
      "Fainting or dizziness",
      "Palpitations",
      "Fatigue or confusion",
      "Slurred speech",
      "Arm/leg weakness, numbness or pain",
      "Unexplained sweating",
    ],
  },
];
