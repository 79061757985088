import { useState } from "react";
import React from "react";
import Image from "next/image";
import ButtonArrow from "../ui/ButtonArrow";

const Testimonials = () => {

  return (

  <div className="container mx-auto my-10 md:my-20">
    <div className="max-w-8xl px-4  py-10 sm:px-6 lg:px-8 lg:py-14 xl:py-16 mx-auto overflow-hidden">
    <div className="py-4 -mx-4">
      <svg className="size-24 text-red-600" width="32" height="32" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                <path d="M7.39762 10.3C7.39762 11.0733 7.14888 11.7 6.6514 12.18C6.15392 12.6333 5.52552 12.86 4.76621 12.86C3.84979 12.86 3.09047 12.5533 2.48825 11.94C1.91222 11.3266 1.62421 10.4467 1.62421 9.29999C1.62421 8.07332 1.96459 6.87332 2.64535 5.69999C3.35231 4.49999 4.33418 3.55332 5.59098 2.85999L6.4943 4.25999C5.81354 4.73999 5.26369 5.27332 4.84476 5.85999C4.45201 6.44666 4.19017 7.12666 4.05926 7.89999C4.29491 7.79332 4.56983 7.73999 4.88403 7.73999C5.61716 7.73999 6.21938 7.97999 6.69067 8.45999C7.16197 8.93999 7.39762 9.55333 7.39762 10.3ZM14.6242 10.3C14.6242 11.0733 14.3755 11.7 13.878 12.18C13.3805 12.6333 12.7521 12.86 11.9928 12.86C11.0764 12.86 10.3171 12.5533 9.71484 11.94C9.13881 11.3266 8.85079 10.4467 8.85079 9.29999C8.85079 8.07332 9.19117 6.87332 9.87194 5.69999C10.5789 4.49999 11.5608 3.55332 12.8176 2.85999L13.7209 4.25999C13.0401 4.73999 12.4903 5.27332 12.0713 5.85999C11.6786 6.44666 11.4168 7.12666 11.2858 7.89999C11.5215 7.79332 11.7964 7.73999 12.1106 7.73999C12.8437 7.73999 13.446 7.97999 13.9173 8.45999C14.3886 8.93999 14.6242 9.55333 14.6242 10.3Z" fill="currentColor"/>
              </svg>
      
                <h2 className="text-3xl md:text-3xl lg:text-4xl tracking-wide uppercase mb-3 ml-4">
    Patient Success Story
                </h2>
    </div>
      <div className="md:grid lg:grid-cols-2 items-start">
        <div className="hidden md:block mb-24 md:mb-6">
          <div className="relative">
          
  
            <Image
                    className="rounded-lg object-contain"
                    src="/patients/JerryGranger.png"
                    alt="Jerry Granger Patient Success Story"
                    width={780}
                    height={420}
                  />
            {/* <div className="scroll-ms-4 md:scroll-ms-0">
                  <div className="text-base font-semibold text-gray-800">Jerry Granger, 55</div>
                  <div className="text-xs text-gray-500">Cypress, TX</div>
                </div> */}
                {/* <ButtonArrow variant="vein"/> */}
    
    
            {/* <div className="absolute bottom-0 start-0 -z-[1] translate-y-10 -translate-x-14">
              <svg className="max-w-20 h-auto text-red-600" width="396" height="440" viewBox="0 0 696 380" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="72.5" cy="29.5" r="29.5" fill="currentColor"/>
                <circle cx="171.5" cy="29.5" r="29.5" fill="currentColor"/>
                <circle cx="270.5" cy="29.5" r="29.5" fill="currentColor"/>
                <circle cx="369.5" cy="29.5" r="29.5" fill="currentColor"/>
                <circle cx="468.5" cy="29.5" r="29.5" fill="currentColor"/>
                <circle cx="567.5" cy="29.5" r="29.5" fill="currentColor"/>
                <circle cx="666.5" cy="29.5" r="29.5" fill="currentColor"/>
                <circle cx="29.5" cy="128.5" r="29.5" fill="currentColor"/>
                <circle cx="128.5" cy="128.5" r="29.5" fill="currentColor"/>
                <circle cx="227.5" cy="128.5" r="29.5" fill="currentColor"/>
                <circle cx="326.5" cy="128.5" r="29.5" fill="currentColor"/>
                <circle cx="425.5" cy="128.5" r="29.5" fill="currentColor"/>
                <circle cx="524.5" cy="128.5" r="29.5" fill="currentColor"/>
                <circle cx="623.5" cy="128.5" r="29.5" fill="currentColor"/>
                <circle cx="72.5" cy="227.5" r="29.5" fill="currentColor"/>
                <circle cx="171.5" cy="227.5" r="29.5" fill="currentColor"/>
                <circle cx="270.5" cy="227.5" r="29.5" fill="currentColor"/>
                <circle cx="369.5" cy="227.5" r="29.5" fill="currentColor"/>
                <circle cx="468.5" cy="227.5" r="29.5" fill="currentColor"/>
                <circle cx="567.5" cy="227.5" r="29.5" fill="currentColor"/>
                <circle cx="666.5" cy="227.5" r="29.5" fill="currentColor"/>
                <circle cx="29.5" cy="326.5" r="29.5" fill="currentColor"/>
                <circle cx="128.5" cy="326.5" r="29.5" fill="currentColor"/>
                <circle cx="227.5" cy="326.5" r="29.5" fill="currentColor"/>
                <circle cx="326.5" cy="326.5" r="29.5" fill="currentColor"/>
                <circle cx="425.5" cy="326.5" r="29.5" fill="currentColor"/>
                <circle cx="524.5" cy="326.5" r="29.5" fill="currentColor"/>
                <circle cx="623.5" cy="326.5" r="29.5" fill="currentColor"/>
                <circle cx="72.5" cy="425.5" r="29.5" fill="currentColor"/>
                <circle cx="171.5" cy="425.5" r="29.5" fill="currentColor"/>
                <circle cx="270.5" cy="425.5" r="29.5" fill="currentColor"/>
                <circle cx="369.5" cy="425.5" r="29.5" fill="currentColor"/>
                <circle cx="468.5" cy="425.5" r="29.5" fill="currentColor"/>
                <circle cx="567.5" cy="425.5" r="29.5" fill="currentColor"/>
                <circle cx="666.5" cy="425.5" r="29.5" fill="currentColor"/>
                <circle cx="29.5" cy="524.5" r="29.5" fill="currentColor"/>
                <circle cx="128.5" cy="524.5" r="29.5" fill="currentColor"/>
                <circle cx="227.5" cy="524.5" r="29.5" fill="currentColor"/>
                <circle cx="326.5" cy="524.5" r="29.5" fill="currentColor"/>
                <circle cx="425.5" cy="524.5" r="29.5" fill="currentColor"/>
                <circle cx="524.5" cy="524.5" r="29.5" fill="currentColor"/>
                <circle cx="623.5" cy="524.5" r="29.5" fill="currentColor"/>
                <circle cx="72.5" cy="623.5" r="29.5" fill="currentColor"/>
                <circle cx="171.5" cy="623.5" r="29.5" fill="currentColor"/>
                <circle cx="270.5" cy="623.5" r="29.5" fill="currentColor"/>
                <circle cx="369.5" cy="623.5" r="29.5" fill="currentColor"/>
                <circle cx="468.5" cy="623.5" r="29.5" fill="currentColor"/>
                <circle cx="567.5" cy="623.5" r="29.5" fill="currentColor"/>
                <circle cx="666.5" cy="623.5" r="29.5" fill="currentColor"/>
              </svg>
            </div> */}
  
          </div>
        </div>  
        <div>
          <blockquote className="relative">
            {/* <svg className="absolute top-0 start-0 transform -translate-x-8 -translate-y-4 size-24 text-red-600" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
              <path d="M7.39762 10.3C7.39762 11.0733 7.14888 11.7 6.6514 12.18C6.15392 12.6333 5.52552 12.86 4.76621 12.86C3.84979 12.86 3.09047 12.5533 2.48825 11.94C1.91222 11.3266 1.62421 10.4467 1.62421 9.29999C1.62421 8.07332 1.96459 6.87332 2.64535 5.69999C3.35231 4.49999 4.33418 3.55332 5.59098 2.85999L6.4943 4.25999C5.81354 4.73999 5.26369 5.27332 4.84476 5.85999C4.45201 6.44666 4.19017 7.12666 4.05926 7.89999C4.29491 7.79332 4.56983 7.73999 4.88403 7.73999C5.61716 7.73999 6.21938 7.97999 6.69067 8.45999C7.16197 8.93999 7.39762 9.55333 7.39762 10.3ZM14.6242 10.3C14.6242 11.0733 14.3755 11.7 13.878 12.18C13.3805 12.6333 12.7521 12.86 11.9928 12.86C11.0764 12.86 10.3171 12.5533 9.71484 11.94C9.13881 11.3266 8.85079 10.4467 8.85079 9.29999C8.85079 8.07332 9.19117 6.87332 9.87194 5.69999C10.5789 4.49999 11.5608 3.55332 12.8176 2.85999L13.7209 4.25999C13.0401 4.73999 12.4903 5.27332 12.0713 5.85999C11.6786 6.44666 11.4168 7.12666 11.2858 7.89999C11.5215 7.79332 11.7964 7.73999 12.1106 7.73999C12.8437 7.73999 13.446 7.97999 13.9173 8.45999C14.3886 8.93999 14.6242 9.55333 14.6242 10.3Z" fill="currentColor"/>
            </svg> */}
    
            <div className="relative z-10">
              {/* <p className="text-xs font-semibold text-gray-500 tracking-wide uppercase mb-3">
  Patient Success Story
              </p>
     */}
              <p className=" text-base font-medium italic text-gray-800 lg:text-md md:leading-normal xl:leading-normal">
              "I credit Dr. Jad Daye with saving my life. I had no idea I had suffered a serious heart attack until Dr. Daye discovered it during a routine physical exam. His quick thinking and expertise allowed him to accurately diagnose the tear in my heart wall and the dangerous blood clot that had formed. Dr. Daye's decisive action in sending me for immediate surgery prevented what could have been a fatal misdiagnosis.Thanks to Dr. Daye, I received the emergency triple bypass surgery and heart wall repair I desperately needed. I'm grateful for his thoroughness during my checkup, his ability to recognize the severity of my condition, and his sound medical judgment that got me the life-saving treatment required. Dr. Daye is an exceptional cardiologist and I wouldn't be here today without him. I strongly urge other men not to skip their checkups with Dr. Daye - it could be a matter of life and death."
              </p>
             <div className="hidden md:block text-base font-semibold text-gray-800">Jerry Granger, 55</div>
                  <div className="hidden md:block text-xs text-gray-500">Cypress, TX</div>
            </div>
    
            <footer className="mt-6">
              <div className="flex items-start rounded-xl">
                <div className="md:hidden rounded-xl mb-4">
                  <Image
                    className="rounded-xl object-contain"
                    src="/patients/JerryGranger.png"
                    alt="Jerry Granger Patient Success Story"
                    width={780}
                    height={420}
                  />
                  <div className="text-base font-semibold text-gray-800">Jerry Granger, 55</div>
                  <div className="text-xs text-gray-500">Cypress, TX</div>
                </div>
           
              </div>
            </footer>
    
            <div className="hidden mt-3 lg:block ml-auto">
              
              {/* <a className="relative group inline-flex h-12 w-28 items-center justify-center border border-black rounded-lg overflow-hidden" href="https://www.houstonchronicle.com/neighborhood/cyfair/article/Cypress-man-says-he-ll-never-miss-another-16276103.php">
              <span className="relative z-10 font-semibold text-black group-hover:text-white transition duration-500">Read More</span>
              <div className="absolute top-0 left-0 h-full w-32 transform -translate-x-full -ml-1 group-hover:-translate-x-0 bg-blue-800 transition duration-500 ease-linear"></div>
              </a> */}
            </div>
          </blockquote>
    
        </div>
    
      </div>
    
    </div>
  </div>
  );
};

export default Testimonials;







