import { medflow } from "constants/medflow";
import React from "react";
import { HiOutlineCheckCircle } from "react-icons/hi2";
// import Beam from "./Beam/Beam";

export const MedFlow = ({ workflowTitle }:any) => {
  return (
    <div className="max-w-3xl mx-auto   divide-zinc-800 relative">
      <div className="absolute h-full w-[4px] bg-gradient-to-b from-transparent via-red-500 to:transparent  -left-4 md:-left-10">
        {/* <Beam showBeam={true} className={`left-1`} /> */}
      </div>
      {/* <h1 className="text-5xl">{workflowTitle || `Heart Evaluation`}</h1> */}
      {medflow.map((item: any, idx: number) => (
        <div key={`medflow-item-${idx}`}>
          <h2 className="text-4xl text-slate-60 font-normal mt-8 mb-2 relative">
            <div className="h-3 md:h-4 w-3 md:w-4 border-2 border-red-500 bg-red-500 rounded-full absolute -left-[20px] md:-left-[46px] top-2 md:top-1" />
            {item.title}
          </h2>
          <p className="text-slate-60 text-lg font-normal">
            {item.subtext}
          </p>
          {/* <h3 className="text-xl font-bold text-slate-60 mt-8 mb-2 relative">   
            {item.service}
          </h3> */}

          <div className="mb-8">
            {item.points.map((point: any, idx: number) => (
              <div
                key={`medflow-item-${idx}`}
                className="flex flex-row space-x-2 items-start my-2"
              >
                <HiOutlineCheckCircle className="text-red-500 mt-[3px] flex-shrink-0" />
                <span className="text-slate-60 font-medium md:text-base">
                  {point}
                </span>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};


// import { medflow } from "constants/medflow";
// import React from "react";
// import { HiOutlineCheckCircle } from "react-icons/hi2";
// import BeamRed from "./BeamRed/BeamRed";

// export const Medflow = () => {
//   return (
//     <div className="max-w-3xl mx-auto   divide-zinc-800 relative">
//       <div className="absolute h-full w-[4px] bg-gradient-to-b from-transparent via-red-500 to:transparent  -left-4 md:-left-10">
//         <BeamRed showBeam={true} className={`left-1`} />
//       </div>
//       {medflow.map((item: any, idx: number) => (
//         <div key={`medflow-item-${idx}`} >
//           <h2 className="text-xl font-bold text-zinc-500 mt-8 mb-4 relative">
//             <div className="h-3 md:h-4 w-3 md:w-4 border-2 border-red-500 bg-zinc-800 rounded-full absolute -left-[20px] md:-left-[46px] top-2 md:top-1" />
//             {item.service}
//           </h2>

//           <div className="mb-8">
//             {item.points.map((point: any, idx: number) => (
//               <div
//                 key={`heartflowone-item-${idx}`}
//                 className="flex flex-row space-x-2 items-start my-2"
//               >
//                 <HiOutlineCheckCircle className="text-red-500 mt-[3px] flex-shrink-0" />
//                 <span className="text-zinc-400 text-sm md:text-base">
//                   {point}
//                 </span>
//               </div>
//             ))}
//           </div>
//         </div>
//       ))}
//     </div>
//   );
// };
