"use client";

import Image from "next/image";
import { Tabs } from "@/components/ui/tabs";
import { MedFlow } from "@/components/MedFlow";
import { ArterialFlow } from "@/components/ArterialFlow";
import { HeartFlow } from "@/components/HeartFlow";
import { HeartFlowOne } from "@/components/HeartFlowOne";
import { HeartFlowTwo } from "@/components/HeartFlowTwo";
import { VeinArterialFlow } from "@/components/VeinArterialFlow";
import { VeinFlowOne } from "@/components/ui-vein/VeinFlowOne";
import { HiOutlineMagnifyingGlassCircle } from "react-icons/hi2";
import { 
  Atom, 
  HeartCrack, 
  SquareActivity,
  HeartPulse,
  Activity,
  Syringe,
  HandHeart, 
} from "lucide-react";


export function TabsOne() {
  const tabs = [
    {
      title: "Know the Signs",
      value: "Heart Evaluation",
      icon: <HiOutlineMagnifyingGlassCircle size={30}/>,
      content: (
        <div className="w-full overflow-hidden relative h-full rounded-2xl py-2 px-10 md:px-14 text-xl md:text-4xl font-bold text-gray-900 bg-white border-solid border border-red-600">
          <HeartFlowTwo />
        </div>
      ),
    },
    {
      title: "Cardiac Evaluation",
      value: "cardiac",
      icon: <HeartPulse />,
      content: (
        <div className="w-full overflow-hidden relative h-full rounded-2xl py-2 px-10 md:px-14 text-xl  md:text-4xl font-bold text-gray-900 bg-white border-solid border border-red-600 ">
          <MedFlow />
        </div>
      ),
    },  
    {
      title: "Cardiac Diagnostic Services",
      value: "Cardiac Diagnostic Services",
      icon: <Activity />,
      content: (
        <div className="overflow-hidden relative h-full rounded-2xl py-2 px-10 md:px-14 text-xl md:text-4xl font-bold text-gray-900 bg-white border-solid border border-red-600">
          <HeartFlowOne />
        </div>
      ),
    },
 
    // {
    //   title: "Vein Evaluation",
    //   value: "Vein Evaluation",
    //   content: (
    //     <div className="w-full overflow-hidden relative h-full rounded-2xl p-10 text-xl md:text-4xl font-bold text-gray-900 bg-gradient-to-br from-blue-100 to-white">
    //       <p>VeinFlowOne</p>
    //       <VeinFlowOne />
    //     </div>
    //   ),
    // },
  ];

  return (
    <div className="container mx-auto my-10 md:my-20">
      <h2 className="leading-tight font-heading tracking-tighter text-[2.5rem] sm:text-[2.81rem] md:text-5xl lg:text-[3.5rem] xl:text-6xl  mb-6 capitalize">Discover Our Comprehensive Cardiac Workup</h2>
      <p className="text-left mb-8 text-lg">
      At Cypress Interventional Cardiologist, we are dedicated to providing comprehensive care for your heart, veins, and vascular health. From your initial consultation to personalized treatment plans, our experienced team is here to guide you every step of the way. With state-of-the-art facilities and cutting-edge procedures, we are committed to helping you achieve optimal heart health.
          </p>
      <div className="h-[41rem] md:h-[45rem] [perspective:1000px] relative flex flex-col mx-auto w-full  items-start justify-start mb-40">
        <Tabs tabs={tabs} />
      </div>
    </div>
  );
}

const DummyContent = () => {
  return (
    <Image
      src="/images/clinic/cypress-cardiovascular-institute-facility.jpg"
      alt="dummy image"
      width="1000"
      height="1000"
      className="object-cover object-left-top h-[60%]  md:h-[90%] absolute -bottom-10 inset-x-0 w-[90%] rounded-xl mx-auto"
    />
  );
};
