import { Container } from "@/components/Container";
import dynamic from 'next/dynamic';
import { ContainerTwo } from "@/components/ContainerTwo";
import { CallToAction } from "@/components/CTA";
// import Hero from "@/components/Hero";
// import { Products } from "@/components/Products";
// import { Services } from "@/components/services";
import { Departments } from "@/components/Departments"
// import { Testimonial } from "@/components/Testimonial";
import { testimonials } from "constants/testimonials";
import type { NextPage } from "next";
import { HeroClinicians } from "@/components/HeroClinicians";
import LogoMain from "icons/logo";
// import { ClinicianCard } from "@/components/ClinicianCard";
// import HeroFeatures from "@/components/HeroFeatures";
// import { CallToActionVein } from "@/components/CTAVein";
import { CTAVeinTwo } from "@/components/CTAVeinTwo";
// import ServicesTab from "@/components/servicesTab";
import { TabsOne } from "@/components/TabsOne";
// import BentoPost from "@/components/BentoPost";
import { InsCard } from "@/components/InsCard";
import TeamSlider from "@/components/TeamSlider";
// import FrequentlyAskedQuestions from "@/components/ui-common/FrequentlyAskedQuestions";
import { CommonFaq } from "@/components/ui-common/CommonFaq";
// import { ComprehensiveHeartCareCTA } from "@/components/ui-heart/ComprehensiveHeartCareCTA";
import Testimonials from "@/components/ui-common/Testimonials";
import Gallery from "@/components/ui-common/Gallery";
import { useRouter } from "next/router";
import CTA from "@/components/ui-common/CTA";
// Adjust the import path as necessary

import { ServiceCatSVGs } from "@/components/ui-common/ServiceCatSVGs";
// import { Diseases } from "@/components/Diseases";



const Home: NextPage = () => {
  const router = useRouter();
  const { slug } = router.query;



  const QnA = [
    {
      question: "What services do you offer?",
      answer: "Our practice offers a wide array of high-quality services and procedures using the latest technology dedicated to patient care, safety, and satisfaction. Some of our services include angioplasty, cardiac PET scans, electrocardiograms, echocardiograms, stress myocardial perfusion scans (nuclear stress tests), vascular studies, pacemaker/ICD insertions, and impedance cardiography (ICG).",
    },
    {
      question: "How can I schedule an appointment?",
      answer: "Simply call us at (281) 955-9158 during our working hours (Monday to Friday, 8:00 am - 5:00 pm) or you can request an appointment online using our form.",
    },
    {
      question: "Do you accept insurance?",
      answer: "Yes, we accept Medicaid, Medicare, and most insurance plans. Please contact us for further details or verification of coverage.",
    },
    {
      question: "What are your clinic hours?",
      answer: "Our clinic hours are Monday to Friday, 8:00 am - 5:00 pm.",
    },
    {
      question: "Where are you located?",
      answer: "We have two locations: 21216 Northwest Fwy Ste 650, Cypress, TX and 21212 Northwest Fwy Ste 255, Cypress, TX. 21216 Northwest Fwy Ste 650, Cypress, TX and 21212 Northwest Fwy Ste 255, Cypress, TX.",
    },
    {
      question: "How do I make an appointment?",
      answer: "Call us at (281) 955-9158 during our working hours or you can request an appointment online using our form.",
    },
  ];
  return (
    <Container
      title={`Cypress Cardiovascular Institute | Expert Interventional Cardiology Services in Cypress, Texas`}
      description={` Welcome to Cypress Cardiovascular Institute, your premier destination for interventional cardiology services in Cypress, Texas. Our skilled team provides advanced heart interventions with compassion and expertise, serving Cypress and surrounding areas including Houston, Tomball, Spring, and Katy.
    `}
    >
      {/* <Hero />
      <div className="relative flex flex-col items-center justify-center mt-10">
      <LogoMain className="h-20 w-10 mb-4 text-zinc-500" />
        <h1 className="text-4xl md:text-7xl font-bold mb-14 relative text-center text-zinc-700">
            Cypress{" "}
            <span className="relative bg-clip-text text-transparent bg-gradient-to-r from-blue-800 to-red-500 z-10">
  ∆∆∆            Cardiovascular Institute∆ </span>
          </h1>
      </div> */}
      <div className="mb-10 -mx-8">

        <HeroClinicians />
      </div>
      {/* <Testimonial testimonial={testimonials[0]} /> */}
      <div id="work" className=" max-w-6xl mx-auto antialiased">

      </div>
      <ServiceCatSVGs />
      {/* <Testimonial testimonial={testimonials[1]} /> */}
      <div className="bg-slate-50">
        <Departments
          titleCard={`Learn More About Vein, Vascular, and Heart Care`}
          linkOne={`/vein-care-101`}
          linkTwo={`/vascular-care-101`}
          linkThree={`/heart-care-101`}
        />
      </div>
      {/* <CallToActionVein 
        headerText={`Are you suffering from vein conditions like varicose veins or chronic venous insufficiency?`}
        bodyText={`Look no further for state-of-the-art treatments using advanced techniques like ClosureFast™ and VenaSeal™. Take action today and schedule an appointment with our skilled physicians for a comprehensive evaluation.`}
        buttonText={`Schedule an Appointment`}
        /> */}
      <CTAVeinTwo
        headingText={`Feel Good About Your Legs Today`}
        painText={`Suffering from Varicose Veins or Spider Veins?`}
      />
      {/* <CTA slug={`/vein-care`} /> */}
      {/* <ComprehensiveHeartCareCTA /> */}
      {/* <Services
        titleOne={``}
        titleTwo={`Services`}
        headerDescription={`We offer a full suite of cutting-edge cardiac services at our state-of-the-art facility in Cypress. Our services include:`}
      /> */}
      <div className="-mx-8">

        {/* <CTAVeinTwo 
        painText={`Suffering from Varicose Veins or Spider Veins?`}
      /> */}
      </div>
      {/* <ClinicianCard /> */}
      {/* <ServicesTab /> */}
      <TabsOne />
      {/* <BentoPost /> */}
      <InsCard />
      <TeamSlider />
      <Gallery />
      <Testimonials />
      <CommonFaq
        faqTitle="Frequently Asked Questions"
        QnA={QnA}
      />

      {/* <FrequentlyAskedQuestions 
        buttonCategory={`vein`} 
        faqTitle="Vein Care FAQs"
        faqDescription="Find answers to common questions about our vein care services."
        faqData={faqData}
      /> */}
      {/* <CallToAction 
        headerText={`We Accept Most Insurance Including Medicare and Medicaid`}
        bodyText={`we understand the importance of accessibility to quality healthcare. That's why we accept most insurance plans, including Medicare and Medicaid. Contact us today to schedule your appointment and take the first step towards better heart health.`}
      /> */}
    </Container>
  );
};

export default Home;


//TODO Add Canonicals