// components/ThreeCard.tsx
import { threecardservices } from "constants/threecardservices";
import React from "react";

export const ServiceCatSVGs = ({ cardTitle }: any) => {
  return (
    <section className="py-12 md:py-24 overflow-hidden -mx-6">
      <div className="container mx-auto px-4">
        <h2 className="font-heading text-6xl md:text-7xl tracking-tighter mb-20 capitalize">
          {cardTitle || `Our Services`}
        </h2>
        <div className="x-auto xl:max-w-none">
          <div className="flex flex-wrap -mx-1.5">
            <div className="w-full  xl:w-1/2 px-1.5 mb-4 xl:mb-0">
              {threecardservices.slice(0, 1).map((service) => (
                <div key={service.id} className="group h-full border hover:bg-red-600 bg-white rounded-3xl hover:text-white text-slate-600">
                {/* <div key={service.id} className="h-full border rounded-3xl"> */}
                  <a href={service.link}>
                    <div className="block mb-16 w-full  rounded-3xl object-cover">
                        <div className={`p-8 ${service.textcolor}`}>{service.svg}</div>
                    </div>
                    <div className="px-6 max-w-xl mx-auto pb-16 text-center ">
                      {/* <span className="items-center">{service.icon}</span> */}
                      <h3 className="block text-4xl mb-4">{service.title}</h3>
                      {/* <h3 className="block text-4xl mb-4">{service.styledtitle}</h3> */}
                      <p className="mb-8">{service.description}</p>
                      <div
                        className="group inline-flex items-center leading-none font-medium pb-2 border-b-2 current"
                       
                      >
                        <span className="mr-4">See {service.title} Services</span>
                        <span className="group-hover:rotate-45 transform transition duration-100">
                          <svg
                            width={11}
                            height={11}
                            viewBox="0 0 11 11"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.5 1.5L1.5 9.5"
                              stroke="currentColor"
                              strokeWidth="1.3"
                              strokeMiterlimit={10}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M9.5 8.83571V1.5H2.16429"
                              stroke="currentColor"
                              strokeWidth="1.3"
                              strokeMiterlimit={10}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                  </a>
                </div>
              ))}
            </div>
            <div className="w-full xl:w-1/2 px-1.5">
              {threecardservices.slice(1).map((service) => (
                <a key={service.id} className={service.cardstyle} href={service.link}>
                {/* <a key={service.id} className="group block mb-3 border rounded-3xl" href={service.link}> */}
                  <div className="group flex flex-wrap justify-between">
                    <div className="w-full md:w-7/12 pl-6 sm:pl-12 pr-5 py-0 self-center">
                      <div className=" py-10">
                        {/* <span>{service.icon}</span> */}
                        <h3 className="block text-4xl mb-4">{service.title}</h3>
                        {/* <h3 className="block text-4xl mb-4">{service.styledtitle}</h3> */}
                        <p className="mb-8">{service.description}</p>
                    <div
                        className="group inline-flex items-center leading-none font-medium pb-2 border-b-2 current"
                       
                      >
                        <span className="mr-4">See {service.title} Services</span>
                        <span className="group-hover:rotate-45 transform transition duration-100">
                          <svg
                            width={11}
                            height={11}
                            viewBox="0 0 11 11"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.5 1.5L1.5 9.5"
                              stroke="currentColor"
                              strokeWidth="1.3"
                              strokeMiterlimit={10}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M9.5 8.83571V1.5H2.16429"
                              stroke="currentColor"
                              strokeWidth="1.3"
                              strokeMiterlimit={10}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>
                      </div>
                      </div>
                    </div>
                    <div className="w-full md:w-5/12 md:transform group-hover:translate-x-4 transition duration-500">
                      <div className="block w-full h-full rounded-3xl object-cover">
                        <div className={`p-8 ${service.textcolor}`}>{service.svg}</div>
                      </div>
                    </div>
                  </div>
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};