import React from 'react';
import Image from 'next/image';
// import bgImage from '/bg/Orb1440x880.png';

export function HeroClinicians() {
  return (
    <section className="relative overflow-hidden bg-slate-50">
      {/* <section className="overflow-hidden bg-slate-50"> */}
      <div className="absolute inset-0">
        <Image
          src={'/bg/Orb1440x880.png'}
          alt="Background Image"
          layout="fill"
          objectFit="cover"
          quality={100}
        />
      </div>
      <div className="relative container mx-auto pl-4 " >

        <div className="relative pt-6 md:pt-6 xl:pt-12">
          <span className="block mb-4 text-sm text-slate-600">
            Your Cardiovascular One-Stop Shop Now Serving Cypress and Surrounding Areas: Houston, Tomball, Spring, and Katy, TX
          </span>
          <div className="max-w-sm sm:max-w-none ">
            <img
              src="/images/staff/dr-daye-and-dr-hust.png"

              alt="clinicians"
              className="hidden mt-52 md:block md:w-2/3 md:-mr-8 lg:w-2/3 float-right   xl:mt-22 2xl:mt-80 drop-shadow-xl"
              style={{
                aspectRatio: "900/745",
                // aspectRatio: "700/579",
                // aspectRatio: "600/496",
                objectFit: "cover",
              }}
              width={900}
              // width={700}
              // width={496}
              height={745}
            // height={579}
            // height={496}
            />
            <h1 className="relative w-full font-heading text-5xl sm:text-6xl md:text-7xl lg:text-8xl xl:text-8xl 2xl:text-9xl  align-middle md:w-1/3 lg:w-1/3 tracking-tighter leading-tight z-9">
              <span>Comprehensive Cardiovascular Care{" "}</span>
              <span>
                <span className="text-blue-800">
                  Veins,
                </span>
                <br />
                <span className="text-red-600">
                  Arteries,
                </span>
                <br />
                {" "}&{" "}
                <span className="text-red-600">
                  Heart
                </span>
                <span className="text-blue-800">
                  {" "}Health
                </span>
              </span>
            </h1>
          </div>

          <div className="relative z-10 flex flex-col sm:flex-row flex-wrap mt-10">
            <div className="pb-5">

              <a
                className="group inline-flex mb-3 sm:mb-0 sm:mr-4 py-5 px-8 font-medium leading-none items-center justify-center text-white bg-blue-800 hover:bg-white hover:text-black border border-black rounded-full transition duration-150"
                href="/contact"
              >
                <span className="mr-2.5">Schedule Appointment</span>
                <span className="group-hover:rotate-45 transform transition duration-75">
                  <svg
                    width="10"
                    height="11"
                    viewBox="0 0 10 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 1.5L1 9.5"
                      stroke="currentColor"
                      strokeWidth="1.3"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M9 8.83571V1.5H1.66429"
                      stroke="currentColor"
                      strokeWidth="1.3"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                </span>
              </a>
            </div>

          </div>
          <div className="block mt-6 -mb-8 md:hidden">
            {/* <div className="block mt-6 -mb-14 md:hidden"> */}
            <Image
              src="/images/staff/dr-daye-and-dr-hust.png"
              alt="clinicians"
              style={{
                aspectRatio: "900/745",
              }}
              layout="responsive"
              width={900}
              height={745}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

