import React, { useState } from 'react';

type QnA = {
  question: string,
  answer: string
}

type FaqProps = {
  QnA: QnA[],
  faqTitle: string,
}

export const CommonFaq = ({ faqTitle, QnA, }: FaqProps) => {
  const [openedQuestion, setOpenedQuestion] = useState<number | null>(null);
  return (
    <section className="mx-auto container my-10 md:my-20 bg-blue-800 p-8 rounded-xl">
      <div className="mx-auto px-4">
        <h1 className="font-heading text-5xl text-white sm:text-6xl md:text-7xl tracking-tighter mb-12">
          {faqTitle || `Add FAQ Title`}
        </h1>
        <div className="flex flex-wrap -mx-4">
          {QnA.map((data, key) => (
            <div className="w-full lg:w-1/2 px-4 mb-6 lg:mb-0" key={key}>
              <div className="lg:max-w-xl">
                <div className="pb-6 px-0 border-b border-white mb-4">
                  <div className="flex w-full justify-between items-center">
                    <h5 className="text-white text-xl xs:text-2xl tracking-tight">{data.question}</h5>
                    <button 
                      onClick={() => {setOpenedQuestion(openedQuestion === key ? null : key)}}
                      className="flex-shrink-0 inline-flex ml-4 items-center justify-center w-12 h-12 text-black-700 bg-white hover:text-black-700 border border-black-700 hover:bg-white rounded-full transition duration-150">
                      <span className={`inline-block transform transition-transform ${openedQuestion === key ? 'rotate-180' : 'rotate-0'}`}>
                        <svg
                          width={18}
                          height={18}
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.459 10.4893L9.21179 14.7365L4.9646 10.4893"
                            stroke="currentColor"
                            strokeWidth="1.04955"
                            strokeMiterlimit={10}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M9.21094 2.84152L9.21094 14.6175"
                            stroke="currentColor"
                            strokeWidth="1.04955"
                            strokeMiterlimit={10}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                    </button>
                  </div>
                  {openedQuestion === key && (
                  <div className="overflow-hidden h-auto duration-500 mt-2" style={{}}>
                    <div className="bg-white px-4 pb-4  pt-1 rounded-md"><p className="max-w-3xl mt-6 text-lg text-black">{data.answer}</p></div>
                  </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};